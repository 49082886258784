<template>
  <div class="offer-accounting">
    <vs-row>
      <vs-col class="custom-col-width" vs-type="flex" vs-justify="left" vs-align="top" vs-w="2">
        <ul class="links-list pt-4 pb-8">
          <li class="mb-2 p-2 pl-6 pr-6" v-bind:class="[$route.name === 'offers-accounting-already-billed' ? 'is-active' : '']">
            <router-link
                :to="{name:'offers-accounting-already-billed'}"
                class="NavigationItem__router-link">Bereits abgerechnet
            </router-link>
          </li>
          <li class="mb-2 p-2 pl-6 pr-6" v-bind:class="[$route.name === 'offers-accounting-positions' ? 'is-active' : '']">
            <router-link
                :to="{name:'offers-accounting-positions'}"
                class="NavigationItem__router-link"> Positions-Abrechnung erstellen
            </router-link>
          </li>
          <li class="mb-2 p-2 pl-6 pr-6" v-bind:class="[$route.name === 'offers-accounting-create-invoice' ? 'is-active' : '']">
            <router-link
                :to="{name:'offers-accounting-create-invoice'}"
                class="NavigationItem__router-link"> Akontorechnung erstellen
            </router-link>
          </li>
          <li class="mb-2 p-2 pl-6 pr-6" v-bind:class="[$route.meta.parent === 'offers-accounting-create-final-invoice' ? 'is-active' : '']">
            <router-link
                :to="{name:'offers-accounting-create-final-invoice-fixed-price'}"
                class="NavigationItem__router-link"> Schlussrechnung erstellen
            </router-link>
          </li>
          <li class="mb-2 p-2 pl-6 pr-6" v-bind:class="[$route.meta.parent === 'offers-accounting-assignment' ? 'is-active' : '']">
            <router-link
                :to="{name:'offers-accounting-assignment-positions'}"
                class="NavigationItem__router-link"> Nachträgliche Zuordnung
            </router-link>
          </li>
          <li class="mb-2 p-2 pl-6 pr-6" v-bind:class="[$route.name === 'offers-billing-profil' ? 'is-active' : '']">
            <router-link
                    :to="{name:'offers-billing-profil'}"
                    class="NavigationItem__router-link"> Abrechnungsprofil & Reports
            </router-link>
          </li>
        </ul>
      </vs-col>

      <vs-col vs-type="flex" class="pl-4" vs-justify="left"  vs-w="10">
        <transition :name="routerTransition" mode="out-in">
          <router-view @changeRouteTitle="changeRouteTitle"></router-view>
        </transition>
      </vs-col>

    </vs-row>
  </div>
</template>

<script>
import themeConfig from '@/../themeConfig.js'

export default {
  name: "OfferAccounting",
  components: {
  },
  data() {
    return {
      routerTransition  : themeConfig.routerTransition || 'none',
      routeTitle        : this.$route.meta.pageTitle || '',
    }
  },
  methods: {
    changeRouteTitle(title) {
      this.routeTitle = title
    }
  },
  watch: {
    "$route"() {
      this.routeTitle = this.$route.meta.pageTitle
    },
  }
}
</script>

<style lang="scss">
.offer-accounting {
  width: 100%;
  background: #fff;
  border-radius: 4px;
  display: block;
  box-shadow: 0px 4px 25px 0px rgba(0,0,0,.2);
  position: relative;
  border: 1px solid #e4e4e4;
  margin: 4px 0;
  .links-list {
    border-right: 1px solid #dccbcb;
    li {
      a {
        color: #626262;
        font-size: 14px;
        &:hover {
          color: #e24733;
        }
      }
      &.is-active {
        color: #e24733;
        border-left: 2px solid #e24733;
        border-bottom: 0;
      }
    }
  }
  .custom-col-width {
    width: 15% !important;
  }
}
</style>
